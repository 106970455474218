<template>
  <el-dialog :visible.sync="dialog" title="支付款项" append-to-body :close-on-click-modal="false" @closed="form = null" width="640px">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-position="right" label-suffix=":">
          <div class="h sb">
            <el-form-item prop="inoutType" label="支付类型">
              <el-radio-group v-model="form.inoutType">
                <el-radio-button label="in">收款</el-radio-button>
                <el-radio-button label="out">退款</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="feeType" label="费用类型">
              <el-select v-model="form.feeType" placeholder="请选择费用类型" style="width: 210px;">
                <el-option label="订单费用" value="orderForm" />
                <el-option label="运费" value="freight" />
                <el-option label="维修费" value="repair" />
                <el-option label="返利" value="rebate" v-if="form.inoutType=='out'" />
                <el-option label="其他" value="other" />
              </el-select>
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="payChannel" :label="form.inoutType === 'in' ? '收款渠道' : '退款渠道'">
              <el-select v-model="form.payChannel" filterable placeholder="请选择收款渠道" style="width: 210px;">
                <el-option label="商场" value="mall" />
                <el-option label="POS" value="store" />
                <el-option label="企业" value="enterprise" />
                <el-option label="APP" value="app" />
                <el-option label="转移" value="change" />
              </el-select>
            </el-form-item>
            <el-form-item prop="payTypeId" :label="form.inoutType === 'in' ? '收款方式' : '退款方式'" label-width="96px">
              <quick-select v-model="form.payTypeId" filterable url="api/shopPayType" :params="{sort: 'displayIndex,asc'}" value-field="id" auto-select-first-option />
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="amount" :label="form.inoutType === 'in' ? '收款金额' : '退款金额'">
              <price-input v-model="form.amount" :min="0.01" style="width: 210px;" />
            </el-form-item>
            <el-form-item prop="payTime" :label="form.inoutType === 'in'  ? '收款日期' : '退款日期'" label-width="96px">
              <el-date-picker :picker-options="pickerOptions0" type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 210px;" />
            </el-form-item>
          </div>
          <el-form-item :label="form.inoutType === 'in' ? '收款凭证' : '退款凭证'" prop="voucherNo" v-if="$entType.get()==='kingkoil'">
            <el-input v-model="form.voucherNo" :maxlength="50" placeholder="收款凭证号,最大50字符" style="width: 210px;" />
          </el-form-item>
          <el-form-item label="票据图片" prop="_pics">
            <file-uploader v-model="form._pics" :entity-id="form.fileId" folder="pay/bill" multiple required />
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.remark" :maxlength="60" :rows="3" resize="none" placeholder="最大45字符" />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="dialog = false">取消</el-button>
          <el-button type="primary" @click="handleSubmit" :loading="saving">确定</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-position="right" label-suffix=":">
          <div class="h">
            <div style="width: 50%;">
              <form-info-item :label="`${form.inoutType === 'in' ? '收款' : '退款'}单号`">{{form.code}}</form-info-item>
              <form-info-item label="支付类型">{{form.inoutType === 'in' ? "收款" : "退款"}}</form-info-item>
              <form-info-item label="支付方式">{{form.payType}}</form-info-item>
              <form-info-item label="支付日期">{{new Date(form.payTime).format("yyyy/MM/dd")}}</form-info-item>
              <form-info-item label="支付金额">￥{{(form.amount / 100).toFixed(2)}}</form-info-item>
            </div>
            <div>
              <form-info-item label="是否审核">
                <dot :type="form.finCheck ? 'success' : 'gray'" same>{{form.finCheck ? '已审核' : '未审核'}}</dot>
              </form-info-item>
              <form-info-item label="审核日期" v-if="form.finCheck">{{new Date(form.finCheckTime).format("yyyy/MM/dd")}}</form-info-item>
              <form-info-item label="是否到账">
                <dot :type="form.inAccount ? 'success' : 'gray'" same>{{form.inAccount ? '已到账' : '未到账'}}</dot>
              </form-info-item>
              <form-info-item label="到账日期" v-if="form.inAccount">{{new Date(form.inAccountTime).format("yyyy/MM/dd")}}</form-info-item>
            </div>
          </div>
          <form-info-item :label="form.inoutType === 'in' ? '收款凭证' : '退款凭证'" v-if="$entType.get()==='kingkoil'">{{form.voucherNo}}</form-info-item>
          <form-info-item label="票据图片">
            <file-uploader :entity-id="form.fileId" folder="pay/bill" multiple readonly empty-text="没有上传票据图片" />
          </form-info-item>

          <form-info-item label="备注">{{form.remark}}</form-info-item>
        </el-form>

        <template v-if="action === 'audit' && !form.finCheck">
          <el-divider content-position="left">审核</el-divider>
          <el-form :model="form" label-position="right" label-suffix=":" label-width="74px">
            <div class="h e">
              <el-form-item label="审核日期">
                <el-date-picker v-model="auditDate" :clearable="false" value-format="timestamp" :picker-options="datePickerOptions" style="width: 180px;" />
              </el-form-item>
              <el-form-item label-width="10px">
                <el-button type="primary" :loading="submiting" @click="doAudit">提交审核</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { add, edit, audit } from "@/api/shopPay";

export default {
  data() {
    let now = new Date();
    return {
      dialog: false,
      saving: false,
      submiting: false,
      target: null,
      form: null,
      action: null,
      auditDate: now.getTime(),
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      rules: {
        payChannel: [{ required: true, message: "渠道为必填项" }],
        feeType: [{ required: true, message: "费用类型为必填项" }],
        payTypeId: [{ required: true, message: "方式为必填项" }],
        payTime: [{ required: true, message: "日期为必填项" }],
        amount: [{ required: true, message: "金额为必填项" }],
        voucherNo: [{ required: true, message: "收款凭证为必填项" }],
        _pics: [{ required: true, message: "票据图片为必填项" }],
      },
      datePickerOptions: {
        disabledDate: function (value) {
          return value > now;
        },
      },
    };
  },
  computed: {
    editable() {
      if (this.form) {
        return (
          !this.action && (!this.target || !this.form.id || !this.form.finCheck)
        );
      }
      return false;
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          if (this.target && this.target.id) {
            this.saving = true;
            let form = JSON.parse(JSON.stringify(this.form));
            if (form.inoutType === "out") form.amount *= -1;
            (this.form.id ? edit : add)(this.target.id, form)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "保存支付款项信息成功!",
                });
                this.$emit("remote-submit", res || this.form);
                this.$emit("payCompleted");
                this.dialog = false;
              })
              .finally((_) => {
                this.saving = false;
              });
          } else {
            this.$emit("localc-submit", this.form);
            this.dialog = false;
          }
        });
    },
    doAudit() {
      if (this.form && this.form.id) {
        this.$confirm(
          `${this.form.inoutType === "out" ? "退款" : "收款"}金额：<b class="${
            this.form.inoutType === "out" ? "fc-e" : "fc-s"
          }">${this.$price(Math.abs(this.form.amount))}</b><br />确定要对此项${
            this.form.inoutType === "out" ? "退款" : "收款"
          }进行审核？`,
          "操作确认",
          { type: "warning", dangerouslyUseHTMLString: true }
        ).then((_) => {
          this.submiting = true;
          audit({
            id: this.form.id,
            finCheckTime: this.auditDate,
          })
            .then((res) => {
              this.$notify({
                title: "审核成功",
                type: "success",
                duration: 2500,
              });
              this.$emit("remote-submit");
              this.dialog = false;
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
      }
    },
    resetForm(target, form, action = null) {
      this.target = target;
      this.action = action;
      if (form) {
        form._pics = null;
        form.amount = Math.abs(form.amount);
      }
      this.form = form || {
        _id: this.$uuid(),
        inoutType: "in",
        feeType: "orderForm",
        fileId: this.$uuid(),
        _pics: null,
        payChannel: "mall",
        finCheck: false,
        payTime: new Date().getTime(),
        amount: 1,
        payType: "",
        remark: "",
      };
      this.dialog = true;
    },
  },
};
</script>